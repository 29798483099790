import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import StepperPage from "./components/StepperPage";
import InitialPage from "./components/InitialPage_General";
import FinishPage from "./components/FinishPage";
import QuotePage from "./components/QuotePage";
import DocumentPage from "./components/DocumentPage";
import BankStatementsPage from "./components/BankStatementsPage";
import PartnerCard from "./components/PartnerCard";
import {
  queryParams,
  setFavicon,
} from "./utils/HelperFunctions";
import "bootstrap/dist/css/bootstrap.min.css";
import Api from "./utils/Api";
import { PartnerResponse } from "./store/Store";
import { LocalSettings, NoIndexList, setPartnerData, Styles } from "./configuration/AppConfig";
import LoadingScreen from "./components/LoadingScreen";
import { FrenchTranslation } from "./configuration/Translation";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

function App() {
  const search = window.location.search;
  const params: QueryParams = queryParams(search);
  const [appIsReady, setAppIsReady] = useState(false);

  useEffect(() => {
    getTranslation(params.language);
    getPartnerData(params.userId);

    ReactPixel.init("432726384255254", undefined, {
      debug: process.env.NODE_ENV === "development",
      autoConfig: false,
    });
    ReactPixel.pageView();
  }, []);

  const getTranslation = (language: string | undefined) => {
    if (language !== undefined) {
      switch (language) {
        case "fr":
          LocalSettings.language = "fr";
          LocalSettings.translation = FrenchTranslation;
          break;
      }
    }
  };

  const getPartnerData = (userId: string | undefined) => {
    if (userId !== undefined) {
      Api.getPartnerData(
        {
          partnerId: userId,
          type: "Self_Partner",
        },
        (result) => {
          const data: PartnerResponse = result.data;
          setPartnerData(data);
          setAppIsReady(true);
        },
        (err) => {
          console.error(err);
          setFavicon(LocalSettings.favicon16Url, "favicon-16");
          setFavicon(LocalSettings.favicon32Url, "favicon-32");
          setFavicon(LocalSettings.faviconAppleUrl, "favicon-apple");
          setAppIsReady(true);
        }
      );
    } else {
      setFavicon(LocalSettings.favicon16Url, "favicon-16");
      setFavicon(LocalSettings.favicon32Url, "favicon-32");
      setFavicon(LocalSettings.faviconAppleUrl, "favicon-apple");
      setAppIsReady(true);
    }
  };

  const setNoIndex = () => {
    // Checking if the page should be no-indexed:
    if (NoIndexList.includes(window.location.href))
      return <meta name="robots" content="noindex" />
  }


  let appContent;
  if (appIsReady) {
    appContent = (
      <div>
        <Router>
          <Switch>
            <Route path="/cards/view" component={PartnerCard} />
            <Route path="/welcome/:partnerName?" component={InitialPage} />
            <Route path="/application/:applicationId" component={StepperPage} />
            <Route
              path="/banking/:applicationId"
              component={BankStatementsPage}
            />
            <Route path="/documents/:applicationId" component={DocumentPage} />
            <Route path="/quote/:applicationId" component={QuotePage} />
            <Route path="/finish" component={FinishPage} />
            <Route render={() => <Redirect to={`/welcome` + search} />} />
          </Switch>
        </Router>
      </div>
    );
  } else {
    appContent = (
      <div>
        <LoadingScreen showMessage={false}></LoadingScreen>
      </div>
    );
  }
  return (<div>
    <Helmet>
      {setNoIndex()}
    </Helmet>
    {appContent}
  </div>)
}

interface QueryParams {
  userId: string | undefined;
  language: string | undefined;
}

export default App;
