import React, { ChangeEvent, KeyboardEvent, useState, useRef } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import "normalize.css";
import usePlacesAutocomplete, { GeocodeResult } from "use-places-autocomplete";
import { getGeocode, getZipCode } from "use-places-autocomplete";
import "./AddressLookupGooglePlaces.css";

let cachedVal = "";
const acceptedKeys = [38, 40, 13, 27];

//@ts-ignore
type Suggestion = google.maps.places.AutocompletePrediction;

function AddressLookupGooglePlaces(props: AddressLookupGooglePlacesProps) {
  const [currIndex, setCurrIndex] = useState<number | null>(null);
  const [searchText, setSearchText] = useState(props.formattedValue);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: "ca" },
      types: ["address"],
    },
  });

  const filterSuggestions = (suggestions: any[]): any[] => {
    return suggestions.filter(
      (d) => d.types.includes("street_address") || d.types.includes("premise")
    );
  };

  const hasSuggestions = status === "OK" && filterSuggestions(data).length > 0;

  const dismissSuggestions = (): void => {
    setCurrIndex(null);
    clearSuggestions();
  };

  const ref = useRef<HTMLDivElement>();

  //@ts-ignore
  useOnclickOutside(ref, dismissSuggestions);

  const handleInput = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
    cachedVal = e.target.value;
  };

  const handleSelect = (suggestion: Suggestion) => (): void => {
    handleGetDetails(suggestion.place_id, suggestion.terms);
  };

  const handleGetDetails = (placeId: string, terms: any[]): void => {
    const parameter = { placeId: placeId };
    getGeocode(parameter).then((geocodeResults: GeocodeResult[]) => {
      getZipCode(geocodeResults[0], false).then((zipCodeResults: any) => {
        dismissSuggestions();

        let street: string, city: string, province: string;

        if (terms.length == 4) {
          // Building number and street name are concatenated:
          street = terms[0].value;
          city = terms[1].value;
          province = getProvinceAbbreviation(terms[2].value);
        } else {
          // Building number and street name are separate:
          street = `${terms[0].value} ${terms[1].value}`;
          city = terms[2].value;
          province = getProvinceAbbreviation(terms[3].value);
        }

        props.onSelected({
          street: street,
          city: city,
          province: province,
          postalCode: zipCodeResults.trim(),
        });
      });
    });
  };

  // In some cases Google Places will return the full province name
  // So we need to get the abbreviation before setting the data:
  const getProvinceAbbreviation = (provinceName: string) => {
    switch(provinceName){
      case "Nova Scotia":
        return "NS"
      default:
        return provinceName
    }
  }

  const handleEnter = (idx: number) => (): void => {
    setCurrIndex(idx);
  };

  const handleLeave = (): void => {
    setCurrIndex(null);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (!hasSuggestions || !acceptedKeys.includes(e.keyCode)) return;

    if (e.keyCode === 13 || e.keyCode === 27) {
      e.preventDefault();
      if (currIndex != null) {
        handleGetDetails(data[currIndex].place_id, data[currIndex].terms);
      }
      return;
    }

    let nextIndex: number | null;

    if (e.keyCode === 38) {
      e.preventDefault();
      nextIndex = currIndex ?? data.length;
      nextIndex = nextIndex != null && nextIndex > 0 ? nextIndex - 1 : null;
    } else {
      nextIndex = currIndex ?? -1;
      nextIndex = nextIndex < data.length - 1 ? nextIndex + 1 : null;
    }

    setCurrIndex(nextIndex);
    setValue(
      nextIndex != null && data[nextIndex]
        ? data[nextIndex].description
        : cachedVal,
      false
    );
  };

  const renderSuggestions = (): JSX.Element | null => {
    const filteredData = filterSuggestions(data);
    const suggestions = filteredData.map(
      (suggestion: Suggestion, idx: number) => {
        const {
          id,
          structured_formatting: { main_text, secondary_text },
        } = suggestion;

        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <li
            key={id}
            id={`ex-list-item-${idx}`}
            className={
              idx === currIndex ? "listItem listItemDarken" : "listItem"
            }
            onClick={handleSelect(suggestion)}
            onMouseEnter={handleEnter(idx)}
            role="option"
            aria-selected={idx === currIndex}
          >
            <h4>{main_text}</h4>{" "}
            <small className="subText">{secondary_text}</small>
          </li>
        );
      }
    );

    if (suggestions.length > 0) {
      return (
        <div>
          {suggestions}
          <li className="logo">
            <img
              src="https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png"
              alt="Powered by Google"
            />
          </li>
        </div>
      );
    } else return null;
  };

  return (
    <div>
      <div>
        <div
          className="autocomplete"
          //@ts-ignore
          ref={ref}
          // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
          role="combobox"
          aria-owns="ex-list-box"
          aria-haspopup="listbox"
          aria-expanded={hasSuggestions}
        >
          <input
            className={
              hasSuggestions
                ? "form-control form-control-lg input inputNoBottomRadius"
                : "form-control form-control-lg input"
            }
            value={props.formattedValue != null ? props.formattedValue : value}
            onChange={handleInput}
            onInput={props.onInput}
            onKeyDown={handleKeyDown}
            disabled={!ready}
            placeholder={props.placeholder}
            type="text"
            aria-autocomplete="list"
            aria-controls="ex-list-box"
            aria-activedescendant={
              currIndex !== null ? `ex-list-item-${currIndex}` : undefined
            }
          />
          {hasSuggestions && (
            <ul
              id="ex-list-box"
              className="listBox"
              onMouseLeave={handleLeave}
              role="listbox"
            >
              {renderSuggestions()}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

type AddressLookupGooglePlacesProps = {
  onSelected(result: AddressLookupResult): void;
  onInput(): void;
  placeholder: string;
  formattedValue: string | null;
};

export type AddressLookupResult = {
  street: string;
  city: string;
  province: string;
  postalCode: string;
};

export default AddressLookupGooglePlaces;
